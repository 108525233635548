import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Header from './components/Header';

import SearchCard from './components/cards/SearchCard';
import WelcomeCard from './components/cards/WelcomeCard';

import SteamEffect from './components/SteamEffect';

import ReactGA from "react-ga4";
const TRACKING_ID = "G-LLB4LZ2H3B";

function App({ currentCard }) {
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.set({ page: currentCard });
    }, [ currentCard ]);
    return (
    <div className="App bg-gray-800">
        <div className="flex flex-col w-full h-screen overflow-x-hidden">
            <Header/>
            <main className="flex flex-grow h-max flex-col content">
                {currentCard === 'searchCard' && (
                    <SearchCard />
                )}
                {currentCard === 'welcomeCard' && (
                    <WelcomeCard />
                )}
            </main>
        </div>
        <SteamEffect/>
    </div>
  );
}

const mapStateToProps = (state) => ({
    currentCard: state.steam.currentCard,
});

export default connect(mapStateToProps, null)(App);
