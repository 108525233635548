import React from "react";
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { changeCard } from '../store/Actions';

const Header = ({ pageInit }) => {
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(changeCard('searchCard'));
    };
    return (
        <div className="px-6 items-center justify-between py-2 content
            bg-gray-800 top-bar">
            <div className="text-white md:text-4xl sm:text-xl text-center">
                <button
                    onClick={handleClick}>
                    <img src="./images/cupOfSteam.png" alt="cup of coffee"
                    className="object-scale-down mx-auto max-h-10 mt-1
                        max-md:max-h-10 max-sm:max-h-7 float-left"/>
                    <div className="float-right pt-1">
                        <h1>Cup of Steam</h1>
                    </div>
                </button>
            </div>
        </div>
    );
}

const mapDispatchToProps = {
    changeCard,
};

export default connect(null, mapDispatchToProps)(Header);
