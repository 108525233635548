import React, { useCallback } from 'react';
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";

const SteamEffect = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {

    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onHover: {
                            enable: false,
                            mode: "repulse",
                        },
                        onclick: {
                            enable: false,
                            mode: "bubble"
                        },
                        resize: false,
                    },
                    modes: {
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 50,
                            duration: 5,
                        },
                        "bubble": {
                            "distance": 150,
                            "size": 200,
                            "duration": 1,
                            "opacity": .05
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#bdbdbd",
                    },
                    move: {
                        direction: "top",
                        enable: true,
                        outModes: {
                            default: "out",
                            top: "out",
                            bottom: "bounce",
                            left: "bounce",
                            right: "bounce",
                        },
                        size: true,
                        speed: {
                            min: 2,
                            max: 4
                        },
                        bounce: false,
                    },
                    line_linked: {
                        enable: true,
                        distance: 60,
                        color: "#bdbdbd",
                        opacity: .5,
                        width: 5
                    },
                    interactivity: {
                        detectsOn: "canvas",
                        events: {
                            resize: true
                        }
                    },
                    detectRetina: true,
                    number: {
                        density: {
                            enable: true,
                            area: 1000,
                        },
                        value: 150,
                    },
                    opacity: {
                        value: .4,
                    },
                    shape: {
                        type: "square",
                    },
                    size: {
                        value: { min: 2, max: 10 },
                    },
                    wobble: {
                        distance: 1,
                        enable: false,
                        move: true,
                        speed: {
                            min: -5,
                            max: 5
                        }
                    },
                    emitters: {
                        direction: "bottom",
                        position: {
                            x: 0,
                            y: 0
                        },
                        rate: {
                            delay: 0.5,
                            quantity: 1
                        },
                        size: {
                            width: 100,
                            height: 0
                        }
                    },
                },
                detectRetina: true,
            }}
        />
    );
}

export default SteamEffect;