import React from "react";
import { connect, useDispatch } from 'react-redux';
import {
    setUserName,
    initSearch,
    changeCard,
} from '../../store/Actions';

const SearchCard = ({
    data,
    state,
    username,
    setUserName,
    initSearch,
    searchButtonExit,
    showLoadingIcons,
    fetchUserIdLoading,
    fetchUserIdSuccess,
    fetchUserProfileLoading,
    fetchUserProfileSuccess,
    fetchUserGameLibraryLoading,
    fetchUserGameLibrarySuccess,
    fetchAILoading,
    fetchAISuccess,
    isCardExit,
    changeCard,
    isError,
}) => {
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setUserName(event.target.value);
    };

    const handleKeyPress = (event) => {
        // console.log(event);
    }

    const handleSearch = async () => {
        if (!username) return; //Todo feedback to ui for no input
        initSearch(username);
    };

    const handleClick = () => {
        dispatch(changeCard('searchCard'));
    };

    return(
        <div className={`self-center content-center h-62 max-w-md
            md: w-1/2
            sm: w-3/4
            bg-gray-300 p-4 mt-20 card-container text-center
            fade active ${isCardExit ? 'fade-out-up' : ''}
            search-card ${showLoadingIcons ? 'expanded' : ''}`}>
            <div className="flex-row pb-5">
                <div className="fex justify-center text-center
                    md:text-2xl
                    sm:text-lg
                    px-5 pb-5">
                    <p>Enter your username
                    <br/>What's your cup of steam?</p>
                    <p className="text-lg">openAI reviews the games in your library and gives you a recommendation</p>
                </div>
                <div className="flex justify-center">
                    <input type="text"
                        onChange={handleChange}
                        value={username}
                        onKeyPress={handleKeyPress}
                        disabled={showLoadingIcons}
                        placeholder="Steam Username"
                        className="placeholder:italic placeholder:text-slate-400 block
                        w-auto border-2 border-slate-300 rounded-md p-2 mb-2 shadow-sm
                        focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
                        text-xl justify-self-center text-center"
                    />
                </div>
                {!showLoadingIcons && (
                    <>
                        <div className="flex justify-center">
                            <button onClick={handleSearch}
                                disabled={showLoadingIcons}
                                className={`bg-blue-600 hover:bg-blue-700 text-white font-bold my-2 py-2 px-4
                                active`}>
                                    Recommend A Game
                                </button>
                        </div>
                    </>
                )}
                {showLoadingIcons && (
                    <>
                        <div className="flex justify-center">
                            <button onClick={handleClick}
                                className={`bg-blue-600 hover:bg-blue-700 text-white font-bold my-2 py-2 px-4
                                active`}>
                                    Reset
                                </button>
                        </div>
                        <div className="grid grid-cols-2 gap-4 pt-5 fadeAndGrow active h-44">
                            <div className={`p-4 h-28 ${fetchUserIdSuccess ? 'bg-green-400/50' : 'bg-gray-400/50'}`}>

                                User Name
                                {fetchUserIdLoading && (
                                    <img src="./images/spinner.png" alt="loading"
                                    className="object-contain w-10 m-auto animate-spin"/>
                                )}
                                {fetchUserIdSuccess && (
                                    <img src="./images/greenCheck.png" alt="loading"
                                    className="object-contain w-10 m-auto"/>
                                )}
                            </div>
                            <div className={`p-4 h-28 ${fetchUserProfileSuccess ? 'bg-green-400/50' : 'bg-gray-400 '}`}>

                                User Profile
                                {fetchUserProfileLoading && (
                                    <img src="./images/spinner.png" alt="loading"
                                    className="object-contain w-10 m-auto animate-spin"/>
                                )}
                                {fetchUserProfileSuccess && (
                                    <img src="./images/greenCheck.png" alt="loading"
                                    className="object-contain w-10 m-auto"/>
                                )}
                            </div>
                            <div className={`p-4 h-28 ${fetchUserGameLibrarySuccess ? 'bg-green-400/50' : 'bg-gray-400 '}`}>

                                Get Games
                                {fetchUserGameLibraryLoading && (
                                    <img src="./images/spinner.png" alt="loading"
                                    className="object-contain w-10 m-auto animate-spin"/>
                                )}
                                {fetchUserGameLibrarySuccess && (
                                    <img src="./images/greenCheck.png" alt="loading"
                                    className="object-contain w-10 m-auto"/>
                                )}
                            </div>
                            <div className={`p-4 h-28 ${fetchAISuccess ? 'bg-green-400/50' : 'bg-gray-400 '}`}>

                                AI Analysis
                                {fetchAILoading && (
                                    <img src="./images/spinner.png" alt="loading"
                                    className="object-contain w-10 m-auto animate-spin"/>
                                )}
                                {fetchAISuccess && (
                                    <img src="./images/greenCheck.png" alt="loading"
                                    className="object-contain w-10 m-auto"/>
                                )}
                            </div>
                        </div>
                    </>
                )}
                { isError && (
                    <div className="bg-red-300 mt-5 py-3">
                        Error Has Occurred, Try Again.  <br/>
                        Private Profiles Won't Work.
                    </div>
                )}
            </div>
        </div>

    );
}

const mapStateToProps = (state) => ({
    data: state.data,
    state: state,
    username: state.steam.username,
    searchButtonExit: state.steam.searchButtonExit,
    showLoadingIcons: state.steam.showLoadingIcons,
    fetchUserIdLoading: state.steam.fetchUserIdLoading,
    fetchUserIdSuccess: state.steam.fetchUserIdSuccess,
    fetchUserProfileLoading: state.steam.fetchUserProfileLoading,
    fetchUserProfileSuccess: state.steam.fetchUserProfileSuccess,
    fetchUserGameLibraryLoading: state.steam.fetchUserGameLibraryLoading,
    fetchUserGameLibrarySuccess: state.steam.fetchUserGameLibrarySuccess,
    fetchAILoading: state.steam.fetchAILoading,
    fetchAISuccess: state.steam.fetchAISuccess,
    isCardExit: state.steam.isCardExit,
    isError: state.steam.isError,
});

const mapDispatchToProps = {
    setUserName,
    initSearch,
    changeCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCard);
