import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../store/Reducers'; // Import your root reducer
import rootSaga from '../store/RootSaga'; // Import your root saga

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();
const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware, logger)
    )
);

sagaMiddleware.run(rootSaga);

export default store;
