import React from "react";
import { connect } from 'react-redux';

const Card = ({ isCardExit, children }) => {
    return(
        <div className={`self-center content-center overflow-y-auto
            overflow-y-auto mx-4
            max-w-sm
            md:text-xl
            sm:text-lg
            bg-gray-300 p-6 mt-5
            card-container fade
            ${isCardExit ? 'fade-out-up' : ''}`}>
            { children }
        </div>
    );
}

const mapStateToProps = (state) => ({
    isCardExit: state.steam.isCardExit,
});

export default connect(mapStateToProps, null)(Card);