import React, { useState } from "react";
import { connect } from 'react-redux';
import Card from './Card.js';
const WelcomeCard = ({
    state,
    gameLibrary,
    isCardExit,
    aiResponse,
}) => {
    const [zeroHourGamesCount, setZeroHourGamesCount] = useState(0);

    React.useEffect(() => {
        let zeroHourGames = 0;
        Object.entries(gameLibrary.games || {}).map((game) => {
            if (Math.ceil(game[1].playtime_forever / 60) === 0) {
                zeroHourGames++;
            }
            return zeroHourGames;
        });
        setZeroHourGamesCount(zeroHourGames);
    }, [gameLibrary]);

    return(
        <Card>
            <img src={state.userProfile.avatarfull} alt="User Avatar"
                className="object-contain mx-auto md:w-auto"/>
            <div className="text-center">
                <div className="">
                    Player: <b>{state.userProfile.personaname}</b>
                    <br/>📚 <b>{gameLibrary.game_count}</b> total games in your library
                    <br/><b>🕹️{Math.ceil(zeroHourGamesCount/gameLibrary.game_count * 100)}%</b> games unplayed
                </div>
                <div className="">
                    <br/>openAI Recommends:
                    <br/><span className="">{ aiResponse }</span>
                </div>
            </div>
        </Card>
    );
}

const mapStateToProps = (state) => ({
    state: state.steam,
    gameLibrary: state.steam.gameLibrary,
    isCardExit: state.steam.isCardExit,
    aiResponse: state.steam.aiResponse,
});

export default connect(mapStateToProps, null)(WelcomeCard);
