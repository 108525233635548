export const PAGE_INIT = 'PAGE_INIT';
export const INIT_PAGE = 'INIT_PAGE';
export const SET_USERNAME = 'SET_USERNAME';
export const INIT_SEARCH = 'INIT_SEARCH';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';
export const SET_USERID = 'SET_USERID';
export const SEARCH_BUTTON_EXIT = 'SEARCH_BUTTON_EXIT';
export const SHOW_LOADING_ICONS = 'SHOW_LOADING_ICONS';
export const HIDE_LOADING_ICONS = 'HIDE_LOADING_ICONS';
export const INIT_FETCH_USERID = 'INIT_FETCH_USERID';
export const FETCH_USERID_SUCCESS = 'FETCH_USERID_SUCCESS';
export const INIT_FETCH_USERPROFILE = 'INIT_FETCH_USERPROFILE';
export const SET_USERPROFILE = 'SET_USERPROFILE';
export const FETCH_USERPROFILE_SUCCESS = 'FETCH_USERPROFILE_SUCCESS';
export const INIT_FETCH_GAME_LIBRARY = 'INIT_FETCH_GAME_LIBRARY';
export const SET_GAME_LIBRARY = 'SET_GAME_LIBRARY';
export const FETCH_GAME_LIBRARY_SUCCESS = 'FETCH_GAME_LIBRARY_SUCCESS';
export const INIT_FETCH_AI = "INIT_FETCH_AI";
export const FETCH_AI_SUCCESS = "FETCH_AI_SUCCESS";
export const GET_TOP_PLAYED_GAMES = "GET_TOP_PLAYED_GAMES";
export const GET_ALL_PLAYED_GAMES = "GET_ALL_PLAYED_GAMES";
export const INIT_LIBRARY_RECOMMENDATION = "INIT_LIBRARY_RECOMMENDATION";

export const CHANGE_CARD = 'CHANGE_CARD';
export const CHANGE_CARD_INIT = 'CHANGE_CARD_INIT';
export const EXIT_CARD = 'EXIT_CARD';
export const SET_CARD = 'SET_CARD';
// move to ducks in a row

export const pageInit = () => ({
    type: PAGE_INIT,
});

export const initPage = () => ({
    type: INIT_PAGE,
});

export const setUserName = (data) => ({
    type: SET_USERNAME,
    payload: data,
});

export const initSearch = (data) => ({
    type: INIT_SEARCH,
    payload: data,
});

export const searchButtonExit = () => ({
    type: SEARCH_BUTTON_EXIT,
});

export const showLoadingIcons = () => ({
    type: SHOW_LOADING_ICONS,
});

export const hideLoadingIcons = () => ({
    type: HIDE_LOADING_ICONS,
});

export const initFetchUserId = () => ({
    type: INIT_FETCH_USERID,
});

export const fetchUserIdSuccess = () => ({
    type: FETCH_USERID_SUCCESS,
});

export const initFetchUserProfile = () => ({
    type: INIT_FETCH_USERPROFILE,
});

export const fetchUserProfileSuccess = (data) => ({
    type: FETCH_USERPROFILE_SUCCESS,
    payload: data,
})

export const initFetchGameLibrary = () => ({
    type: INIT_FETCH_GAME_LIBRARY,
});

export const fetchGameLibrarySuccess = (data) => ({
    type: FETCH_GAME_LIBRARY_SUCCESS,
    payload: data,
});

export const initFetchAI = (data) => ({
    type: INIT_FETCH_AI,
});

export const fetchAISuccess = (data) => ({
    type: FETCH_AI_SUCCESS,
    payload: data,
});

export const changeCard = (data) => ({
    type: CHANGE_CARD,
    payload: data,
});

export const exitCard = () => ({
    type: EXIT_CARD,
});

export const setCard = (data) => ({
    type: SET_CARD,
    payload: data
});

export const fetchDataFailure = (error) => ({
  type: FETCH_DATA_FAILURE,
  payload: error,
});

export const getTopPlayedGames = (data) => ({
    type: GET_TOP_PLAYED_GAMES,
    payload: data,
});

export const getAllPlayedGames = (data) => ({
    type: GET_ALL_PLAYED_GAMES,
    payload: data,
});

export const initLibraryRecommendation = () => ({
    type: INIT_LIBRARY_RECOMMENDATION
});
