import { select, takeLatest, takeEvery, take, put, call, delay, race } from 'redux-saga/effects';
import {
  INIT_SEARCH,
  CHANGE_CARD,
  searchButtonExit,
  showLoadingIcons,
  hideLoadingIcons,
  initFetchUserId,
  fetchUserIdSuccess,
  fetchDataFailure,
  initFetchUserProfile,
  fetchUserProfileSuccess,
  initFetchGameLibrary,
  fetchGameLibrarySuccess,
  getTopPlayedGames,
  initFetchAI,
  fetchAISuccess,
  changeCard,
  exitCard,
  setCard,
} from '../store/Actions';
import {
    fetchUserIdApi,
    fetchUserProfile,
    fetchGameLibrary,
    fetchOpenAI,
} from '../api/Api';

const selectTopPlayedGames = (state) => state.steam.topPlayedGames;

function* initSearch(data) {
    try {
        // inspect results from steam, will alert if username not found
        yield put(searchButtonExit());
        yield delay(500);
        yield put(showLoadingIcons());

        yield delay(500);
        yield put(initFetchUserId());
        const userIdResponse = yield call(fetchUserIdApi, data.payload);
        const userId = userIdResponse.data.data.steamid;
        yield put(fetchUserIdSuccess(userId));

        yield delay(500);
        yield put(initFetchUserProfile());
        const userProfileResponse = yield call(fetchUserProfile, userId);
        const userProfile = userProfileResponse.data.data;
        yield put(fetchUserProfileSuccess(userProfile));

        yield delay(500);
        yield put(initFetchGameLibrary());
        const gameLibraryResponse = yield call(fetchGameLibrary, userId);
        const gameLibrary = gameLibraryResponse.data.data;
        yield put(fetchGameLibrarySuccess(gameLibrary));
        yield put(getTopPlayedGames());
        const topPlayedGames = yield select(selectTopPlayedGames);
        // loop and get the title over every game


        yield delay(500);
        yield put(initFetchAI());
        const aiRecommendationReponse = yield call(fetchOpenAI, topPlayedGames);
        const aiRecommendation = aiRecommendationReponse.data.data;
        console.log(aiRecommendation);
        yield put(fetchAISuccess(aiRecommendation));

        yield delay(1000);
        yield put(changeCard('welcomeCard'));
    } catch (error) {
        yield put(hideLoadingIcons());
        yield put(fetchDataFailure(error.response));
    }
}

function* changeCardInit(data) {
    try {
        yield put(exitCard());
        yield delay(1000);
        yield put(setCard(data.payload));
    } catch (error) {
        yield put(hideLoadingIcons());
        yield put(fetchDataFailure(error.response));
    }
}

function* dataSaga() {
    yield takeLatest(CHANGE_CARD, changeCardInit);
    yield takeEvery(INIT_SEARCH, function* (...args) {
        yield race({
            task: call(initSearch, ...args),
            cancel: take(CHANGE_CARD)
        })
    });
}

export default dataSaga;
