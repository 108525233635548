import { combineReducers } from 'redux';

import {
  PAGE_INIT,
  INIT_PAGE,
  SET_USERNAME,
  SET_USERID,
  INIT_SEARCH,
  SEARCH_BUTTON_EXIT,
  SHOW_LOADING_ICONS,
  HIDE_LOADING_ICONS,
  INIT_FETCH_USERID,
  FETCH_USERID_SUCCESS,
  INIT_FETCH_USERPROFILE,
  FETCH_USERPROFILE_SUCCESS,
  INIT_FETCH_GAME_LIBRARY,
  FETCH_GAME_LIBRARY_SUCCESS,
  GET_TOP_PLAYED_GAMES,
  GET_ALL_PLAYED_GAMES,
  INIT_FETCH_AI,
  FETCH_AI_SUCCESS,
  CHANGE_CARD,
  EXIT_CARD,
  SET_CARD,
  FETCH_DATA_FAILURE,
} from '../store/Actions';

const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
    isCardExit: false,
    currentCard: 'searchCard',
    nextCard: null,
    username: '',
    // username: null,
    userId: null,
    showLoadingIcons: false,
    fetchUserIdLoading: false,
    fetchUserIdSuccess: false,
    fetchUserIdError: false,
    fetchUserProfileLoading: false,
    fetchUserProfileSuccess: false,
    fetchUserProfileError: false,
    userProfile: null,
    fetchUserGameLibraryLoading: false,
    fetchUserGameLibrarySuccess: false,
    fetchUserGameLibraryError: false,
    gameLibrary: null,
    allPlayedGames: null,
};

const newState = {
    data: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
    isCardExit: false,
    nextCard: null,
    // username: null,
    userId: null,
    showLoadingIcons: false,
    fetchUserIdLoading: false,
    fetchUserIdSuccess: false,
    fetchUserIdError: false,
    fetchUserProfileLoading: false,
    fetchUserProfileSuccess: false,
    fetchUserProfileError: false,
    userProfile: null,
    fetchUserGameLibraryLoading: false,
    fetchUserGameLibrarySuccess: false,
    fetchUserGameLibraryError: false,
    fetchAILoading: false,
    fetchAISuccess: false,
    fetchAIError: false,
    gameLibrary: null,
    allPlayedGames: null,
};

const steamReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGE_INIT:
            return {
                ...state,
                ...initialState
            }
        case INIT_PAGE:
            return {
                ...state,
                ...initialState
            }
        case SET_USERNAME:
            return {
                ...state,
                username: action.payload,
            }
        case SET_USERID:
            return {
                ...state,
                userId: action.payload,
            }
        case SEARCH_BUTTON_EXIT:
            return {
                ...state,
                searchButtonExit: true,
            }
        case INIT_SEARCH:
            return {
                ...state,
            }
        case SHOW_LOADING_ICONS:
            return {
                ...state,
                searchButtonExit: false,
                showLoadingIcons: true,
                isError: false,
                fetchUserIdLoading: false,
                fetchUserIdSuccess: false,
                fetchUserIdError: false,
                fetchUserProfileLoading: false,
                fetchUserProfileSuccess: false,
                fetchUserProfileError: false,
                userProfile: null,
                fetchUserGameLibraryLoading: false,
                fetchUserGameLibrarySuccess: false,
                fetchUserGameLibraryError: false,
                fetchAILoading: false,
                fetchAISuccess: false,
                fetchAIError: false,
            }
        case HIDE_LOADING_ICONS:
            return {
                ...state,
                showLoadingIcons: false,
            }
        case INIT_FETCH_USERID:
            return {
                ...state,
                fetchUserIdLoading: true,
            }
        case FETCH_USERID_SUCCESS:
            return {
                ...state,
                userId: action.payload,
                fetchUserIdLoading: false,
                fetchUserIdSuccess: true,
            }
        case FETCH_DATA_FAILURE:
            return {
                ...state,
                isError: true,
                errorMessage: action.payload,
            }
        case INIT_FETCH_USERPROFILE:
            return {
                ...state,
                fetchUserProfileLoading: true,
            }
        case FETCH_USERPROFILE_SUCCESS:
            return {
                ...state,
                fetchUserProfileLoading: false,
                fetchUserProfileSuccess: true,
                userProfile: action.payload,
            }
        case INIT_FETCH_GAME_LIBRARY:
            return {
                ...state,
                fetchUserGameLibraryLoading: true,
            }
        case FETCH_GAME_LIBRARY_SUCCESS:
            return {
                ...state,
                fetchUserGameLibraryLoading: false,
                fetchUserGameLibrarySuccess: true,
                gameLibrary: action.payload,
            }
        case GET_TOP_PLAYED_GAMES:
            const games = state.gameLibrary.games;
            games.sort(function(a, b) {
                return a["playtime_forever"] - b["playtime_forever"];
            });
            games.reverse();
            let topPlayedGames = [];
            let index = 0;
            const maxCount = 20;
            games.map((game) => {
                if (index > maxCount) return false;
                topPlayedGames.push(`${game['name']}`);
                return index++;
            });
            return {
                ...state,
                topPlayedGames: topPlayedGames,
            }
        case GET_ALL_PLAYED_GAMES:
            games.sort(function(a, b) {
                return a["playtime_forever"] - b["playtime_forever"];
            });
            games.reverse();
            let allPlayedGames = [];
            games.map((game) => {
                return allPlayedGames.push(`${game['name']} at ${game['playtime_forever']} minutes`);
            });
            return {
                ...state,
                allPlayedGames: allPlayedGames,
            }
        case INIT_FETCH_AI:
            return {
                ...state,
                fetchAILoading: true,
            }
        case FETCH_AI_SUCCESS:
            return {
                ...state,
                fetchAILoading: false,
                fetchAISuccess: true,
                aiResponse: action.payload,
            }
        case CHANGE_CARD:
            return {
               ...state,
            }
        case EXIT_CARD:
            return {
                ...state,
                isCardExit: true,
            }
        case SET_CARD:
            if (action.payload === 'searchCard') {
                return{
                    ...state,
                    ...newState,
                    currentCard: action.payload,
                    isCardExit: false,
                }
            } else {
                return {
                    ...state,
                    currentCard: action.payload,
                    isCardExit: false,
                }
            }
        default:
            return {
                ...state
            }
    }
};

const rootReducer = combineReducers({
  steam: steamReducer
});

export default rootReducer;
